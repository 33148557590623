import {
  TextareaAutosize,
  Container,
  OutlinedInput,
  MenuItem,
  Select,
  TextField,
  CssBaseline,
  Autocomplete,
  Button,
} from '@mui/material';
import { useCookies } from 'react-cookie';
import React, { useState } from 'react';
import { debounce } from 'lodash';
import { fetchData } from '../../utils/apis';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
const genres = ['Action', 'Comedy', 'Thriller', 'Fantasy', 'Sci-Fi', 'Horror'];

const EditBook = () => {
  const [title, setTitle] = useState('');
  const [subTitle, setSubTitle] = useState('');
  const [shortText, setShortText] = useState('');
  const [text, setText] = useState('');
  const [cookie] = useCookies();
  const [genre, setGenre] = useState([]);
  const [books, setBooks] = useState([]);
  const [book, setBook] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [cover, setCover] = useState(null);

  const handleSelectChange = (event) => {
    const {
      target: { value },
    } = event;
    setGenre(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const url = `document/${book._id}/update`;
    const options = {
      updates: {
        genres: genre,
        title: title,
        subtitle: subTitle,
        shortText: shortText,
        text: text,
      },
    };
    await fetchData(url, options, 'post', cookie.authorization);
  };

  const lodashSearch = debounce(async (e) => {
    e.preventDefault();
    const data = await handleSearch(e.target.value);
    let fields = [];
    if (data.length > 0) {
      data.forEach((element) => {
        fields.push({ label: element.title, id: element._id });
      });
      setBooks(fields);
    }
  }, 500);

  const handleSearch = async (criteria) => {
    const url = `documents/search/${criteria}`;
    return (await fetchData(url, null, 'get', cookie.authorization)).result;
  };

  const handleSelectBook = async (e, v) => {
    e.preventDefault();
    const url = `document/${v.id}`;
    const getBook = (await fetchData(url, null, 'get', cookie.authorization))
      .result;

    if (getBook) {
      const { title, subtitle, genres, text, shortText, cover, thumbnail } =
        getBook;
      setBook(getBook);
      setTitle(title);
      setGenre(genres);
      setShortText(shortText);
      setText(text);
      setSubTitle(subtitle);
      setCover(cover);
      setThumbnail(thumbnail);
    }
  };

  return (
    <Container component='main' maxWidth='md'>
      <CssBaseline />
      <div className={'flex flex-col items-center'}>
        <form>
          <Autocomplete
            disablePortal
            id='combo-box-demo'
            options={books}
            onChange={(e, v) => {
              handleSelectBook(e, v);
            }}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                onChange={(e) => {
                  if (e.target.value.length > 2) lodashSearch(e);
                }}
                label='Book'
              />
            )}
          />
        </form>
        {book !== null && (
          <form className={'w-full gap-1'} onSubmit={handleSubmit}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              value={title}
              id='title'
              label='Title'
              name='title'
              autoComplete='title'
              autoFocus
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              value={subTitle}
              name='subTitle'
              label='subTitle'
              type='subTitle'
              id='subTitle'
              autoComplete='current-subTitle'
              onChange={(e) => {
                setSubTitle(e.target.value);
              }}
            />
            <TextareaAutosize
              maxRows={4}
              aria-label='maximum height'
              placeholder='Short Text: This Book is About...'
              required
              value={shortText}
              className='p-1 min-w-[850px] border border-gray-500 rounded-sm min-h-[100px]'
              onChange={(e) => {
                setShortText(e.target.value);
              }}
            />
            <ReactQuill
              theme='snow'
              value={text}
              onChange={(value) => {
                setText(value);
              }}
              placeholder={'Write something awesome...'}
            />
            <div className='space-x-2 relative top-2'>
              <Select
                multiple
                displayEmpty
                required
                value={genre}
                onChange={handleSelectChange}
                input={<OutlinedInput />}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <em>Placeholder</em>;
                  }

                  return selected.join(', ');
                }}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem disabled value=''>
                  <em>Placeholder</em>
                </MenuItem>
                {genres.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </div>
            {(cover || thumbnail) !== '' && (
              <div className='h-[60px] bg-gray-300 relative top-3 flex space-x-5'>
                <img src={cover} alt={title} />
                <img src={thumbnail} alt={title} />
              </div>
            )}
            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              className={'relative top-5'}
            >
              Submit
            </Button>
          </form>
        )}
      </div>
    </Container>
  );
};

export default EditBook;
