import {
  OutlinedInput,
  MenuItem,
  Select,
  TextField,
  Button,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import AddIcon from '@mui/icons-material/Add';
import { useCookies } from 'react-cookie';
import React, { useState, useEffect } from 'react';
import { fetchData, getBase64 } from '../../utils/apis';
import { debounce } from 'lodash';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import InfiniteScroll from 'react-infinite-scroller';
const EditSubscriber = () => {
  const [interest, setInterest] = useState([]);
  const [oldEmail, setOldEmail] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [professions, setProfessions] = useState([]);
  const [interests, setInterests] = useState([]);
  const [profession, setProfession] = useState('');
  const [gender, setGender] = useState('');
  const [date, setDate] = useState(new Date('2014-08-18T21:11:54'));
  const [image, setImage] = useState('');
  const [newImage, setNewImage] = useState(null);
  const [cookie] = useCookies();
  const [userList, setUserList] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [nextPage, setNextPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getCategories = async () => {
      const interests = (
        await fetchData('interests', null, 'get', cookie.authorization)
      ).result;
      const professions = (
        await fetchData('professions', null, 'get', cookie.authorization)
      ).result;
      const { pagination, result } = await fetchData(
        'users/subscribers-list',
        null,
        'get',
        cookie.authorization
      );
      setNextPage(pagination.currentPage + 1);
      if (pagination.totalPages > pagination.currentPage) setHasMore(true);
      else setHasMore(false);
      setInterests(interests);
      setProfessions(professions);
      setUserList(result);
    };
    getCategories();
  }, []);

  const getNextPage = async () => {
    if (hasMore && !isLoading) {
      setIsLoading(true);
      const { result, pagination } = await fetchData(
        `users/subscribers-list?page=${nextPage}`,
        null,
        'get',
        cookie.authorization
      ).then((data) => {
        setIsLoading(false);
        return data;
      });
      if (pagination.totalPages <= pagination.currentPage) {
        setHasMore(false);
      }
      setNextPage(pagination.currentPage + 1);
      setUserList((messages) => [...result.reverse(), ...messages]);
    }
  };

  const delayLoad = () => {
    setTimeout(getNextPage, 300);
  };

  const handleFileInputChange = async (e) => {
    let file = e.target.files[0];

    const result = await getBase64(file)
      .then((result) => {
        return (file['base64'] = result);
      })
      .catch((err) => {
        console.log(err);
      });

    return result;
  };

  const handleImage = async (e) => {
    e.preventDefault();
    const url = 'user/update/image';
    const update = { email: oldEmail, image: newImage };
    await fetchData(url, update, 'post', cookie.authorization);
  };

  const handleEmail = async (e) => {
    e.preventDefault();
    const url = `user/update/email`;
    const update = { email: email, oldEmail: oldEmail };
    await fetchData(url, update, 'post', cookie.authorization).then(
      (result) => {
        if (result) setOldEmail(email);
      }
    );
  };

  const getUser = async (e, email) => {
    e.preventDefault();
    setUserList([]);
    const url = `user/${email}/search`;
    const data = (await fetchData(url, null, 'get', cookie.authorization))
      .result[0];
    console.log(data);
    setEmail(data.email);
    setOldEmail(data.email);
    setName(data.name);
    setGender(data.gender);
    setDate(data.DOB);
    setInterest(data.interests ? data.interests : []);
    setImage(data.image ? data.image : '');
    setProfession(data.profession ? data.profession : '');
  };

  const searchUsers = async (name) => {
    const url = `users/search/${name}`;
    const { result } = await fetchData(url, null, 'get', cookie.authorization);
    console.log(result);
    setUserList(result);
  };

  const lodashSearch = debounce(async (e) => {
    e.preventDefault();
    setUserList([]);
    if (e.target.value.length > 2) await searchUsers(e.target.value);
  }, 500);

  const handleDelete = async (e) => {
    e.preventDefault();
    const url = `user/${oldEmail}/delete`;
    await fetchData(url, null, 'get', cookie.authorization).then(
      ({ success }) => {
        if (success) {
          setEmail('');
          setOldEmail('');
          setName('');
          setDate(new Date.now());
          setProfession('');
          setInterest([]);
          setImage('');
        }
      }
    );
  };
  const handleInterests = async (e) => {
    e.preventDefault();
    const url = `user/update/interests`;
    const update = { interests: interest, email: oldEmail };
    await fetchData(url, update, 'post', cookie.authorization);
  };

  const handleChanges = async (e) => {
    e.preventDefault();
    const url = `user/update`;
    const update = {
      email: oldEmail,
      updates: {
        name: name,
        DOB: date,
        profession: profession,
        gender: gender,
      },
    };
    await fetchData(url, update, 'post', cookie.authorization);
  };

  const handleSelectChange = (event) => {
    const {
      target: { value },
    } = event;
    setInterest(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };
  return (
    <div className='w-full p-20 flex flex-col h-full space-y-14 flex-wrap'>
      <div className=' flex w-full flex-col space-y-10 items-center'>
        <form className='flex ml-10 flex-col w-[300px] items-center'>
          <TextField
            variant='outlined'
            margin='normal'
            required
            className='w-[400px]'
            id='name'
            label='name'
            name='name'
            autoComplete='name'
            autoFocus
            onChange={(e) => {
              lodashSearch(e);
            }}
          />
          <InfiniteScroll
            hasMore={hasMore}
            threshold={10}
            isReverse={true}
            loadMore={delayLoad}
          >
            <List
              sx={{
                width: '100%',
                maxHeight: 300,
                maxWidth: 360,
                bgcolor: 'background.paper',
              }}
              className={'overflow-y-auto flex flex-col'}
            >
              {userList.map((user) => {
                return (
                  <button
                    type='submit'
                    onClick={(e) => {
                      getUser(e, user.email);
                    }}
                  >
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <img
                            src={user.image}
                            alt={user.name}
                            className={'bg-slate-600 w-12 rounded-full'}
                          />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={user.name}
                        secondary={user.email}
                      />
                    </ListItem>
                  </button>
                );
              })}
            </List>
          </InfiniteScroll>
        </form>
        {oldEmail && (
          <div className='w-full flex-col space-y-10 flex flex-wrap items-center space-x-10 '>
            <form
              className='flex ml-10 flex-col items-center'
              onSubmit={handleChanges}
            >
              <TextField
                variant='outlined'
                margin='normal'
                required
                fullWidth
                value={name}
                id='name'
                label='name'
                name='name'
                autoComplete='name'
                autoFocus
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              <TextField
                variant='outlined'
                margin='normal'
                required
                fullWidth
                value={gender}
                id='gender'
                label='gender'
                name='gender'
                autoComplete='gender'
                autoFocus
                onChange={(e) => {
                  setGender(e.target.value);
                }}
              />
              <Select
                className='min-h-12 min-w-[400px]'
                displayEmpty
                required
                value={profession}
                onChange={(e, v) => {
                  // console.log(e, v);
                  setProfession(v.props.value);
                }}
                input={<OutlinedInput />}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem disabled value=''>
                  <em>Professions</em>
                </MenuItem>
                {professions.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
              <div className='relative top-4 flex items-center'>
                <LocalizationProvider
                  className={'w-full'}
                  dateAdapter={AdapterDateFns}
                >
                  <DateTimePicker
                    label='Date of Birth'
                    value={date}
                    className='w-[400px]'
                    onChange={(newValue) => setDate(newValue)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
              <Button
                className='relative top-8 w-[400px]'
                type='submit'
                variant='contained'
                color='primary'
              >
                Change Details
              </Button>
            </form>
            <form
              className='relative top-10 flex flex-col items-center '
              onSubmit={handleImage}
            >
              <div className='w-[200px] h-[100px] rounded-md bg-gray-500'>
                <img
                  className='object-contain  w-[200px] h-[100px]'
                  src={image}
                  alt={name}
                />
              </div>
              <input
                accept='image/*'
                className={'hidden'}
                id='contained-button-cover'
                required
                type='file'
                onChange={(e) => {
                  handleFileInputChange(e).then((res) => {
                    setNewImage(res);
                  });
                }}
              />
              {newImage && (
                <div className='w-[200px] h-[100px] rounded-md bg-gray-500 mb-10'>
                  <img
                    className='object-contain  w-[200px] h-[100px]'
                    src={newImage}
                    alt={name}
                  />
                </div>
              )}
              <label
                className='relative   top-4'
                htmlFor='contained-button-cover'
              >
                <Button variant='outlined' component='span'>
                  <AddIcon /> New Image
                </Button>
              </label>
              <Button
                className='relative top-8  w-[400px]'
                type='submit'
                variant='contained'
                color='primary'
              >
                Change Image
              </Button>
            </form>
            <div>
              <form
                className='w-[500px] items-center rounded-md flex flex-col mt-12 justify-center min-h-[200px] p-5'
                onSubmit={handleInterests}
              >
                <Select
                  className='min-h-12 min-w-[400px]'
                  multiple
                  displayEmpty
                  required
                  value={interest}
                  onChange={handleSelectChange}
                  input={<OutlinedInput />}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <em>interests</em>;
                    }

                    return selected.join(', ');
                  }}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem disabled value=''>
                    <em>Placeholder</em>
                  </MenuItem>
                  {interests.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  className={'relative top-5  w-[400px]'}
                >
                  Change interests
                </Button>
              </form>
              <form
                className='flex flex-col items-center top-10 relative w-[500px]'
                onSubmit={handleEmail}
              >
                <TextField
                  variant='outlined'
                  margin='normal'
                  required
                  className='w-[400px]'
                  value={email}
                  id='email'
                  label='change email'
                  name='email'
                  autoComplete='email'
                  autoFocus
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  className='w-[400px]'
                >
                  Change Email
                </Button>
              </form>
            </div>
          </div>
        )}
      </div>
      {oldEmail && (
        <form
          className=' flex justify-center items-center'
          onSubmit={handleDelete}
        >
          <div className='w-1/2'>
            <Button
              className='relative top-8'
              type='submit'
              fullWidth
              variant='contained'
              color='error'
            >
              Delete subscriber
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};

export default EditSubscriber;
