import React, { useState, useEffect, cloneElement } from 'react';
import { styled } from '@mui/material/styles';
import { useCookies } from 'react-cookie';
import { fetchData } from '../utils/apis';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import { TextField, Button } from '@mui/material';
const Categories = () => {
  const [genres, setGenres] = useState([]);
  const [professions, setProfessions] = useState([]);
  const [interests, setInterests] = useState([]);
  const [cookie] = useCookies();
  const [genre, setGenre] = useState('');
  const [profession, setProfession] = useState('');
  const [interest, setInterest] = useState('');

  const categories = [
    {
      name: 'Genre',
      get: 'documents/genres',
      post: 'documents/genre/create',
      delete: 'documents/genre/delete',
      setValues: setGenres,
      values: genres,
      setValue: setGenre,
      value: genre,
    },

    {
      name: 'Interest',
      get: 'interests',
      post: 'interest/create',
      delete: 'interest/delete',
      setValues: setInterests,
      values: interests,
      setValue: setInterest,
      value: interest,
    },

    {
      name: 'Profession',
      get: 'professions',
      post: 'profession/create',
      delete: 'profession/delete',
      setValues: setProfessions,
      values: professions,
      setValue: setProfession,
      value: profession,
    },
  ];
  const Demo = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
  }));
  const getCategory = async (field) => {
    field.setValues(
      (await fetchData(field.get, null, 'get', cookie.authorization)).result
    );
  };

  const createCategory = async (field) => {
    await fetchData(
      field.post,
      { title: field.value },
      'post',
      cookie.authorization
    );
    await getCategory(field);
  };

  useEffect(() => {
    const getCategories = async () => {
      categories.forEach((category) => {
        getCategory(category);
      });
    };
    getCategories();
  }, []);
  const handleDelete = async (field, value) => {
    await fetchData(
      field.delete,
      { title: value },
      'post',
      cookie.authorization
    );
    await getCategory(field);
  };

  return (
    <div className='flex justify-center space-x-10'>
      <Grid container spacing={2}>
        {categories.map((category) => {
          return (
            <Grid item xs={12} md={4}>
              <Typography sx={{ mt: 4, mb: 2 }} variant='h6' component='div'>
                {category.name}
              </Typography>
              <div className='flex space-x-2 justify-center items-center'>
                <TextField
                  variant='outlined'
                  margin='normal'
                  required
                  className='w-1/2'
                  value={category.value}
                  id={category.name}
                  label={category.name}
                  name={category.name}
                  autoComplete={category.name}
                  autoFocus
                  onChange={(e) => {
                    category.setValue(e.target.value);
                  }}
                />
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  className={'relative h-[50px] top-1 w-1/2'}
                  onClick={() => {
                    createCategory(category);
                  }}
                >
                  Add {category.name}
                </Button>
              </div>
              <Demo>
                <List
                  sx={{
                    width: '100%',
                    position: 'relative',
                    overflow: 'auto',
                    maxHeight: 300,
                  }}
                >
                  {category.values.map((value) => {
                    return (
                      <ListItem
                        className='border-b border-gray-800'
                        secondaryAction={
                          <IconButton
                            onClick={() => handleDelete(category, value)}
                            edge='end'
                            aria-label='delete'
                          >
                            <DeleteIcon />
                          </IconButton>
                        }
                      >
                        <ListItemText primary={value} />
                      </ListItem>
                    );
                  })}
                </List>
              </Demo>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default Categories;
