import { Link } from 'react-router-dom';
import { useState } from 'react';
import Logo from '../images/logo.png';
const Header = (props) => {
  const [dropdown, setDropdown] = useState(false);
  const handleChange = () => {
    setDropdown((dropdown) => !dropdown);
  };
  return (
    <nav class='flex items-center w-full  justify-between flex-wrap bg-blue-500 p-6'>
      <div className='flex items-center'>
        <div class='flex items-center flex-shrink-0 text-white mr-6'>
          <img className='w-10 mr-3' src={Logo} alt={'doggel'} />

          <span class='font-semibold text-xl tracking-tight'>Doggel</span>
        </div>
        <div class='block lg:hidden'>
          <button
            onClick={handleChange}
            class='flex items-center px-3 py-2 border rounded text-blue-200 border-blue-400 hover:text-white hover:border-white'
          >
            <svg
              class='fill-current h-3 w-3'
              viewBox='0 0 20 20'
              xmlns='http://www.w3.org/2000/svg'
            >
              <title>Menu</title>
              <path d='M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z' />
            </svg>
          </button>
        </div>
        <div className='text-white text-xl lg:flex hidden font-semibold '>
          Admin Dashboard
        </div>
      </div>

      <div class='  lg:flex lg:items-center lg:w-auto'>
        <div
          class={`text-sm space-x-4 lg:flex-grow ${
            dropdown ? '' : 'lg:flex hidden'
          }`}
        >
          <Link to={'/faq'}>
            <li class='block mt-4 lg:inline-block lg:mt-0 text-blue-200 hover:text-white'>
              FAQs
            </li>
          </Link>
          <Link to={'/books'}>
            <li class='block mt-4 lg:inline-block lg:mt-0 text-blue-200 hover:text-white'>
              Books
            </li>
          </Link>
          <Link to={'/subscriber'}>
            <li class='block mt-4 lg:inline-block lg:mt-0 text-blue-200 hover:text-white'>
              Subscribers
            </li>
          </Link>
          <Link to={'/admin'}>
            <li class='block mt-4 lg:inline-block lg:mt-0 text-blue-200 hover:text-white'>
              Admin
            </li>
          </Link>
          <Link to={'/report'}>
            <li class='block mt-4 lg:inline-block lg:mt-0 text-blue-200 hover:text-white'>
              Report
            </li>
          </Link>
          <Link to={'/chat'}>
            <li class='block mt-4 lg:inline-block lg:mt-0 text-blue-200 hover:text-white'>
              Chat
            </li>
          </Link>
          <Link to={'/plan'}>
            <li class='block mt-4 lg:inline-block lg:mt-0 text-blue-200 hover:text-white'>
              Plan
            </li>
          </Link>
          <Link to={'/categories'}>
            <li class='block mt-4 lg:inline-block lg:mt-0 text-blue-200 hover:text-white'>
              Categories
            </li>
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Header;
