import { Autocomplete, Button, TextField } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import { blueGrey } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import { debounce } from 'lodash';
import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import ReactQuill from 'react-quill';
import { fetchData } from '../../utils/apis';

const Pending = () => {
  const [cookie] = useCookies();
  const [books, setBooks] = useState([]);
  const [book, setBook] = useState(null);
  const handleSubmit = async (e, pending) => {
    e.preventDefault();
    const url = `documents/${book._id}/set-pending`;
    await fetchData(url, { pending: pending }, 'post', cookie.authorization);
  };

  const getPendingBooks = async (e) => {
    const url = `documents/pending`;
    e.preventDefault();
    const data = await fetchData(url, null, 'get', cookie.authorization);
    if (data.success) {
      let fields = [];
      if (data.result.length > 0) {
        data.result.forEach((element) => {
          fields.push({ label: element.title, id: element._id });
        });
        setBooks(fields);
      }
    }
  };

  const handleSubmitDelete = async (e) => {
    e.preventDefault();
    const url = `document/${book._id}/delete`;
    const data = await fetchData(url, null, 'get', cookie.authorization);
    if (data.success) {
      setBook(null);
    }
  };

  const lodashSearch = debounce(async (e) => {
    e.preventDefault();
    const data = await handleSearch(e.target.value);
    let fields = [];
    if (data.length > 0) {
      data.forEach((element) => {
        fields.push({ label: element.title, id: element._id });
      });
      setBooks(fields);
    }
  }, 500);

  const handleSearch = async (criteria) => {
    const url = `documents/search/${criteria}`;
    return (await fetchData(url, null, 'get', cookie.authorization)).result;
  };

  const handleSelectBook = async (e, v) => {
    e.preventDefault();
    const url = `document/${v.id}`;
    const getBook = (await fetchData(url, null, 'get', cookie.authorization))
      .result;
    if (getBook) {
      setBook(getBook);
    }
  };

  return (
    <div className='flex flex-col justify-between h-[80vh] w-full items-center'>
      <div className='flex md:flex-row flex-col w-full'>
        <div className='flex flex-col space-y-20 md:w-1/2 w-full  relative left-10 '>
          <form>
            <Autocomplete
              disablePortal
              id='combo-box-demo'
              options={books}
              onOpen={getPendingBooks}
              onChange={(e, v) => {
                handleSelectBook(e, v);
              }}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onChange={(e) => {
                    if (e.target.value.length >= 3) lodashSearch(e);
                  }}
                  label='Book'
                />
              )}
            />
          </form>
          {book !== null && (
            <Card sx={{ width: 400, height: 500 }}>
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: blueGrey[900] }} aria-label='recipe'>
                    <CardMedia
                      component='img'
                      height='194'
                      image='/static/images/cards/paella.jpg'
                      alt=''
                    />
                  </Avatar>
                }
                title={book.title}
                subheader={book.subtitle}
              />
              <div className='h-60 flex justify-center bg-gray-400'>
                {book.thumbnail && (
                  <img className='h-60' src={book.thumbnail} alt={book.title} />
                )}
              </div>
              <CardContent>
                <Typography
                  className='overflow-y-scroll h-[170px]'
                  variant='body2'
                  color='text.secondary'>
                  {book.shortText}
                </Typography>
              </CardContent>
            </Card>
          )}
        </div>
        {book !== null && (
          <>
            <div className='relative space-y-10 md:w-1/2 w-full md:p-3 p-0 '>
              <h1 className='text-3xl'>Content</h1>
              <ReactQuill
                className='h-[70vh]'
                readOnly={true}
                value={book.text}
              />
            </div>
          </>
        )}
      </div>
      {book !== null && (
        <div className='space-x-5 top-10 relative flex'>
          <form onSubmit={(e) => handleSubmit(e, false)}>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              className={'relative top-5'}>
              Allow Book
            </Button>
          </form>
          <form onSubmit={(e) => handleSubmit(e, true)}>
            <Button
              type='submit'
              variant='contained'
              color='error'
              className={'relative top-5'}>
              Deny Book
            </Button>
          </form>
          <form onSubmit={(e) => handleSubmitDelete(e)}>
            <Button
              type='submit'
              variant='contained'
              color='error'
              className={'relative top-5'}>
              Delete Book
            </Button>
          </form>
        </div>
      )}
    </div>
  );
};

export default Pending;
