import {
  TextareaAutosize,
  Container,
  OutlinedInput,
  MenuItem,
  Select,
  TextField,
  CssBaseline,
  Button,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useCookies } from 'react-cookie';
import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { fetchData, getBase64 } from '../../utils/apis';
const genres = ['Action', 'Comedy', 'Thriller', 'Fantasy', 'Sci-Fi', 'Horror'];

const AddBook = () => {
  const [title, setTitle] = useState('');
  const [subTitle, setSubTitle] = useState('');
  const [shortText, setShortText] = useState('');
  const [text, setText] = useState('');
  const [cookie] = useCookies();
  const [genres, setGenres] = useState([]);
  const [genre, setGenre] = useState([]);
  const [thumbnail, setThumbnail] = useState(null);
  const [cover, setCover] = useState(null);

  useEffect(() => {
    const getGenres = async () => {
      const { result } = await fetchData(
        'documents/genres',
        null,
        'get',
        cookie.authorization
      );
      setGenres(result);
    };
    getGenres();
  }, []);

  const handleSelectChange = (event) => {
    const {
      target: { value },
    } = event;
    setGenre(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleFileInputChange = async (e) => {
    let file = e.target.files[0];

    const result = await getBase64(file)
      .then((result) => {
        return (file['base64'] = result);
      })
      .catch((err) => {
        console.log(err);
      });

    return result;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const url = 'document/create';
    const options = {
      genres: genre,
      cover,
      thumbnail,
      title,
      subtitle: subTitle,
      shortDescription: shortText,
      text,
    };
    await fetchData(url, options, 'post', cookie.authorization);
  };

  return (
    <Container component='main' maxWidth='md'>
      <CssBaseline />
      <div className={'flex flex-col items-center'}>
        <form className={'w-full gap-1'} onSubmit={handleSubmit}>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='title'
            label='Title'
            name='title'
            autoComplete='title'
            autoFocus
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          />
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            name='subTitle'
            label='subTitle'
            type='subTitle'
            id='subTitle'
            autoComplete='current-subTitle'
            onChange={(e) => {
              setSubTitle(e.target.value);
            }}
          />
          <TextareaAutosize
            maxRows={4}
            aria-label='maximum height'
            placeholder='Short Text: This Book is About...'
            required
            className='p-1 min-w-[850px] border border-gray-500 rounded-sm min-h-[100px]'
            onChange={(e) => {
              setShortText(e.target.value);
            }}
          />
          <ReactQuill
            theme='snow'
            value={text}
            onChange={(value) => {
              setText(value);
            }}
            placeholder={'Write something awesome...'}
          />
          <div className='space-x-2 relative top-2'>
            <Select
              multiple
              displayEmpty
              required
              value={genre}
              onChange={handleSelectChange}
              input={<OutlinedInput />}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <em>Genres</em>;
                }

                return selected.join(', ');
              }}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem disabled value=''>
                <em>Genres</em>
              </MenuItem>
              {genres.map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
            <input
              accept='image/*'
              className={'hidden'}
              id='contained-button-thumbnail'
              required
              type='file'
              onChange={(e) => {
                handleFileInputChange(e).then((res) => {
                  setThumbnail(res);
                });
              }}
            />
            <label htmlFor='contained-button-thumbnail'>
              <Button variant='outlined' component='span'>
                <AddIcon /> Thumbnail
              </Button>
            </label>
            <input
              accept='image/*'
              className={'hidden'}
              id='contained-button-cover'
              required
              type='file'
              onChange={(e) => {
                handleFileInputChange(e).then((res) => {
                  setCover(res);
                });
              }}
            />
            <label htmlFor='contained-button-cover'>
              <Button variant='outlined' component='span'>
                <AddIcon /> Cover
              </Button>
            </label>
          </div>
          {(cover || thumbnail) !== '' && (
            <div className='h-[60px] bg-gray-300 relative top-3 flex space-x-5'>
              <img src={cover} alt={title} />
              <img src={thumbnail} alt={title} />
            </div>
          )}
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            className={'relative top-5'}
          >
            Submit
          </Button>
        </form>
      </div>
    </Container>
  );
};

export default AddBook;
