import React, { useState, useRef } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { TextField } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { fetchData } from '../utils/apis';
import { useCookies } from 'react-cookie';

const handleResolve = async (report, feedback, auth) => {
  const url = `report/${report._id}/resolve`;
  const options = { feedback: feedback };
  await fetchData(url, options, 'post', auth);
};

export default function Reports() {
  const [cookie] = useCookies();
  const [reports, setReports] = useState([]);
  const [counter, setCounter] = useState(1);
  const getReports = async (e) => {
    e.preventDefault();
    const url = `reports?resolved=false&page=${counter}`;
    const { result } = await fetchData(url, null, 'get', cookie.authorization);
    setReports((reports) => reports.concat(result));
    setCounter((counter) => counter + 1);
  };

  return (
    <div>
      <div className='flex flex-wrap p-5'>
        {reports.map((report) => {
          return <Report report={report} cookie={cookie} />;
        })}
      </div>
      {(reports % 25 !== 0 || counter === 1) && (
        <div className='w-full flex justify-center'>
          <form className='w-[300px]' onSubmit={getReports}>
            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              className={'relative '}
            >
              Fetch Reports
            </Button>
          </form>
        </div>
      )}
    </div>
  );
}

const Report = (props) => {
  const ref = useRef();
  const { report, cookie } = props;
  return (
    <Card sx={{ maxWidth: 345 }} key={report._id}>
      {report.image && (
        <CardMedia
          sx={{
            maxHeight: 120,
            objectFit: 'contain',
            backgroundColor: 'rgb(203 213 225)',
          }}
          component='img'
          alt='green iguana'
          height='160'
          image={report.image}
        />
      )}
      <CardContent>
        <Typography gutterBottom variant='h5' component='div'>
          {report.subject}
        </Typography>
        <Typography variant='body2' color='text.secondary'>
          {report.description}
        </Typography>
      </CardContent>
      <TextField inputRef={ref} className='w-full' />
      <div className='flex p-2 space-x-2 '>
        <Button
          type='submit'
          fullWidth
          variant='contained'
          color='primary'
          className={'relative '}
          onClick={() =>
            handleResolve(report, ref.current.value, cookie.authorization)
          }
        >
          Resolve
        </Button>
      </div>
    </Card>
  );
};
