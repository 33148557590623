import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import toast from 'react-hot-toast';
import { fetchData, getBase64 } from '../../utils/apis';
import { Button } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroller';
const Messages = ({ chat }) => {
  const [messages, setMessages] = useState([]);
  const [text, setText] = useState('');
  const [cookie] = useCookies();
  const [image, setImage] = useState(undefined);
  const [hasMore, setHasMore] = useState(false);
  const [nextPage, setNextPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const delayLoad = () => {
    setTimeout(getNextPage, 300);
  };

  const getNextPage = async () => {
    if (hasMore && !isLoading) {
      setIsLoading(true);
      const { result, pagination } = await fetchData(
        `chat/${chat._id}/messages?page=${nextPage}`,
        null,
        'get',
        cookie.authorization
      ).then((data) => {
        setIsLoading(false);
        return data;
      });
      if (pagination.totalPages <= pagination.currentPage) {
        setHasMore(false);
      }
      setNextPage(pagination.currentPage + 1);
      setMessages((messages) => [...result.reverse(), ...messages]);
    }
  };

  const closeChat = async () => {
    await fetchData(
      `chat/${chat._id}/close`,
      null,
      'get',
      cookie.authorization
    ).then(() => {
      window.location.reload();
    });
  };

  const handleFileInputChange = async (e) => {
    let file = e.target.files[0];

    const result = await getBase64(file)
      .then((result) => {
        return (file['base64'] = result);
      })
      .catch((err) => {
        console.log(err);
      });

    return result;
  };

  const sendMessage = async () => {
    const url = `chat/${chat._id}/send-message`;
    let options = {};

    if (text.trim() === '' && image === undefined)
      return toast.error('invalid Input');
    if (text.trim() !== '') options.message = text;
    if (image !== undefined) options.image = image;

    await fetchData(url, options, 'post', cookie.authorization);
    setMessages((messages) => [...messages, { text: text, image: image }]);
  };

  const getMessages = async () => {
    setIsLoading(true);
    const { result, pagination } = await fetchData(
      `chat/${chat._id}/messages`,
      null,
      'get',
      cookie.authorization
    );
    if (result.length > 0) {
      setText('');
      setNextPage(pagination.currentPage + 1);
      if (pagination.totalPages > pagination.currentPage) setHasMore(true);
      else setHasMore(false);
      setImage(undefined);
      setMessages(result.reverse());
      setIsLoading(false);
    }
  };
  useEffect(() => {
    console.log(image);
  }, [image]);
  useEffect(() => {
    setMessages([]);
    setText('');
    getMessages();
  }, [chat]);

  return (
    chat !== null && (
      <div className='lg:w-3/4 w-2/3 h-full overflow-hidden flex flex-col items-end'>
        <div className='flex-1 h-full w-full pl-2 pb-1 sm:pl-6 justify-between flex flex-col '>
          <div className='flex sm:items-center  justify-between py-3 border-b-2 border-gray-200'>
            <div className='relative flex items-center space-x-4'>
              <div className='relative'>
                <img
                  src={chat.subscriber?.image}
                  alt={chat.subscriber?.name}
                  className='w-10 sm:w-16 h-10 sm:h-16 rounded-full'
                />
              </div>
              <div className='flex flex-col leading-tight'>
                <div className='text-2xl mt-1 flex items-center'>
                  <span className='text-gray-700 mr-3'>
                    {chat.subscriber?.name}
                  </span>
                </div>
              </div>
            </div>
            <Button
              onClick={() => {
                closeChat();
              }}
              type='submit'
              variant='contained'
              color='success'
              className={'relative top-5'}
            >
              Close Chat
            </Button>
          </div>
          <div
            id='messages'
            className='flex flex-col space-y-4 p-3 overflow-y-auto'
          >
            <InfiniteScroll
              isReverse={true}
              hasMore={hasMore}
              loadMore={delayLoad}
            >
              {messages.map((message, i, arr) => {
                if (message.isUser) {
                  return (
                    <div className='flex items-end p-1'>
                      <div className='flex flex-col space-y-2 text-xs max-w-xs mx-2 order-2 items-start'>
                        <div>
                          <span className='px-4 py-2 rounded-lg inline-block bg-gray-300 text-gray-600'>
                            {message.text}
                          </span>
                          {message.image !== undefined && (
                            <img
                              src={message.image}
                              alt={message.text}
                              className='rounded-md'
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className='flex items-end p-1 justify-end'>
                      <div className='flex flex-col space-y-2 text-xs max-w-xs mx-2 order-1 items-end'>
                        <div className='flex flex-col items-end'>
                          <span className='px-4 py-2 rounded-lg inline-block rounded-br-none bg-blue-600 text-white '>
                            {message.text}
                          </span>
                          {message.image !== undefined && (
                            <img
                              className='rounded-md'
                              src={message.image}
                              alt={message.text}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </InfiniteScroll>
          </div>
          <div className='border-t-2 border-gray-200 px-4 pt-4 mb-2 sm:mb-0'>
            {image !== undefined && <img className='h-[60px] ' src={image} />}
            <div className='relative flex'>
              <input
                onChange={(e) => {
                  setText(e.target.value);
                }}
                value={text}
                type='text'
                placeholder='Write your message!'
                className='w-full focus:outline-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 pl-12 bg-gray-200 rounded-md py-3'
              />
              <div className='absolute right-0 items-center inset-y-0 hidden sm:flex'>
                <input
                  accept='image/*'
                  className={'hidden'}
                  id='contained-button-thumbnail'
                  required
                  type='file'
                  onChange={(e) => {
                    handleFileInputChange(e).then((res) => {
                      setImage(res);
                    });
                  }}
                />
                <label
                  htmlFor='contained-button-thumbnail'
                  className='inline-flex items-center justify-center rounded-full h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                    className='h-6 w-6 text-gray-600'
                  >
                    <path
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      d='M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z'
                    ></path>
                    <path
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      d='M15 13a3 3 0 11-6 0 3 3 0 016 0z'
                    ></path>
                  </svg>
                </label>

                <button
                  onClick={() => {
                    sendMessage();
                  }}
                  type='button'
                  className='inline-flex items-center justify-center rounded-lg px-4 py-3 transition duration-500 ease-in-out text-white bg-blue-500 hover:bg-blue-400 focus:outline-none'
                >
                  <span className='font-bold'>Send</span>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 20 20'
                    fill='currentColor'
                    className='h-6 w-6 ml-2 transform rotate-90'
                  >
                    <path d='M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z'></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Messages;
