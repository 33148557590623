import { Link } from 'react-router-dom';

const Home = () => {
  const links = [
    'books',
    'subscriber',
    'faq',
    'admin',
    'report',
    'chat',
    'plan',
    'categories',
  ];
  return (
    <div className='w-full flex justify-center'>
      <div className='max-w-[800px] max-h-[700px] flex justify-evenly  relative top-10  flex-wrap'>
        {links.map((link) => {
          return (
            <Link
              to={`/${link}`}
              className='w-40 h-40 ml-10 p-2 mt-6 rounded-lg transition-all hover:scale-105 hover:border-1 hover:bg-gray-400 bg-slate-300 text-center flex items-center justify-center'
            >
              <div className='text-xl capitalize font-semibold text-black'>
                {link}
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};
export default Home;
