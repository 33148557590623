import CreatePlan from './addplan';
import DisplayPlans from './getplans';
const Plans = () => {
  return (
    <div className='flex'>
      <CreatePlan />
      <DisplayPlans />
    </div>
  );
};

export default Plans;
