import React, { useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import { Link } from '@mui/material';
import { Grid } from '@mui/material';
import Container from '@mui/material/Container';
import { CircularProgress } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { setUser } from '../utils/apis';
import { useCookies } from 'react-cookie';
export default function SignIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [cookie, setCookies] = useCookies();
  const handleSignIn = async (e) => {
    e.preventDefault();
    setLoading(true);
    await axios
      .post('https://api.doggel.co.uk/api/v1/login', {
        email,
        password,
      })
      .then(async (res) => {
        setLoading(false);
        setCookies('authorization', res.data.token, { path: '/' });
        setUser({ authorization: res.data.token }, setCookies);
        window.location.reload();
      })
      .catch((e) => {
        toast.error(e.response.data?.error || 'Error', { duration: 5000 });
        console.log(e, 'console error');
        setLoading(false);
      })
      .finally(setLoading(false));
  };

  return (
    <div
      style={{ backgroundColor: '#ffffff', marginBottom: '8%' }}
      className='flex items-center h-[80vh]'
    >
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <div className={'flex flex-col items-center'}>
          <form className={'w-full gap-1'} onSubmit={handleSignIn}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='email'
              label='Email Address'
              name='email'
              autoComplete='email'
              autoFocus
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              name='password'
              label='Password'
              type='password'
              id='password'
              autoComplete='current-password'
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            {loading && (
              <div className='flex justify-center'>
                <CircularProgress />
              </div>
            )}

            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              className={'space-x-3 space-y-2'}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link
                  href='Resetpassword'
                  variant='body2'
                  style={{ color: 'black', fontweight: 'bold' }}
                  onClick={() => {}}
                >
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </div>
  );
}
