import Header from './header/header';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import FAQ from './pages/faq';
import Books from './pages/books/books';
import EditSubscriber from './pages/subscriber/editSubscriber';
import Admin from './pages/admin';
import SignIn from './pages/signIn';
import Chats from './pages/chat/chats';
import { useCookies } from 'react-cookie';
import { setUser } from './utils/apis';
import { Toaster } from 'react-hot-toast';
import Reports from './pages/report';
import Plans from './pages/plans/plans';
import Categories from './pages/categories';
import Home from './pages/home';
import { useEffect } from 'react';
function App() {
  const [cookie, setCookie] = useCookies();
  useEffect(() => {
    setUser(cookie, setCookie);
  }, []);
  console.log(cookie);
  return (
    <div>
      <Toaster />
      <Router>
        {cookie.userDetails?.isAdmin ? (
          <>
            <Header />
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/chat' element={<Chats />} />
              <Route path='/faq' element={<FAQ />} />
              <Route path='/books' element={<Books />} />
              <Route path='/subscriber' element={<EditSubscriber />} />
              <Route path='/admin' element={<Admin />} />
              <Route path='/report' element={<Reports />} />
              <Route path='/plan' element={<Plans />} />
              <Route path='/categories' element={<Categories />} />
            </Routes>
          </>
        ) : (
          <Routes>
            <Route path='/' element={<SignIn />}></Route>
            <Route path='*' element={<Navigate to={'/'} replace />}></Route>
          </Routes>
        )}
      </Router>
    </div>
  );
}

export default App;
