import { Button } from '@mui/material';
import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import toast from 'react-hot-toast';
import { fetchData } from '../../utils/apis';
import Messages from './messages';
import InfiniteScroll from 'react-infinite-scroller';

const Chats = () => {
  const [cookie] = useCookies();
  const [chats, setChats] = useState([]);
  const [open, setOpen] = useState(null);
  const [nextPage, setNextPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const getNextPage = async () => {
    if (hasMore && !isLoading) {
      isLoading(true);
      const { result, pagination } = await fetchData(
        `chats?closed=false&limit=12&page=${nextPage}`,
        null,
        'get',
        cookie.authorization
      ).then((data) => {
        setIsLoading(false);
        return data;
      });
      if (pagination.totalPages <= pagination.currentPage) {
        setHasMore(false);
      }
      setNextPage(pagination.currentPage + 1);
      setChats((chats) => [...chats, ...result]);
    }
  };
  const delayLoad = () => {
    setTimeout(getNextPage, 300);
  };
  useEffect(() => {
    const getChats = async () => {
      const { result, pagination } = await fetchData(
        'chats?closed=false&limit=12',
        null,
        'get',
        cookie.authorization
      ).then((data) => {
        setIsLoading(false);
        return data;
      });
      if (pagination.totalPages > pagination.currentPage) setHasMore(true);
      setNextPage(pagination.currentPage + 1);
      setChats(result);
    };
    getChats();
  }, []);

  return (
    <div className='flex justify-start h-[92vh] w-full'>
      <div className='lg:w-1/4 w-1/3 h-full'>
        <h1 className='text-xl p-2 h-[5%] text-white font-semibold bg-cyan-500 rounded-r-md'>
          Chats
        </h1>
        <div className='pl-2 bg-white flex flex-col justify-between h-[95%] rounded-lg border shadow-md sm:pl-8 dark:bg-gray-800 dark:border-gray-700'>
          <div className='flow-root h-full overflow-y-scroll scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch'>
            <ul
              role='list'
              className='divide-y  divide-gray-200 dark:divide-gray-700'
            >
              <InfiniteScroll hasMore={hasMore} loadMore={delayLoad}>
                {chats.map((chat) => {
                  {
                    if (chat.subscriber) {
                      return (
                        <li
                          onClick={() => {
                            setOpen(chat);
                          }}
                          className='py-3 sm:py-4'
                        >
                          <div className='flex items-center space-x-4'>
                            <div className='flex-shrink-0'>
                              <img
                                className='w-8 h-8 rounded-full'
                                src={chat.subscriber.image}
                                alt='Neil image'
                              />
                            </div>
                            <div className='flex-1 min-w-0'>
                              <p className='text-sm font-medium text-gray-900 truncate dark:text-white'>
                                {chat.subscriber.name}
                              </p>
                              <p className='text-sm text-gray-500 truncate dark:text-gray-400'>
                                {chat.subject}
                              </p>
                            </div>
                          </div>
                        </li>
                      );
                    }
                  }
                })}
              </InfiniteScroll>
            </ul>
          </div>
        </div>
      </div>

      <Messages chat={open} />
    </div>
  );
};

export default Chats;
