import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { fetchData } from '../../utils/apis';
import { useCookies } from 'react-cookie';

const DisplayPlans = () => {
  const [plans, setPlans] = useState([]);
  const [cookie] = useCookies();
  const getPLans = async () => {
    const { result } = await fetchData(
      'plans',
      null,
      'get',
      cookie.authorization
    );
    setPlans(result);
  };
  useEffect(() => {
    getPLans();
  }, []);
  const handleDelete = async (id) => {
    await fetchData(`plan/${id}/delete`, null, 'get', cookie.authorization);
    getPLans();
  };
  return (
    <div className='w-full flex flex-wrap'>
      {plans.map((plan) => {
        return (
          <Card sx={{ maxWidth: 345 }} key={plan._id}>
            <CardContent>
              <Typography gutterBottom variant='h5' component='div'>
                {plan.planName}
              </Typography>
              <Typography gutterBottom variant='body2' component='div'>
                {plan.savings}
              </Typography>
              <Typography gutterBottom variant='body2' component='div'>
                {plan.amount}
              </Typography>
              <Typography gutterBottom variant='body2' component='div'>
                {plan.type}
              </Typography>
              <Typography gutterBottom variant='body1' color='text.secondary'>
                {plan.id}
              </Typography>
              <Typography variant='body2' color='text.secondary'>
                {plan.description}
              </Typography>
            </CardContent>

            <div className='flex p-2 space-x-2 '>
              <Button
                type='submit'
                fullWidth
                variant='contained'
                color='error'
                className={'relative '}
                onClick={() => handleDelete(plan._id)}
              >
                Delete
              </Button>
            </div>
          </Card>
        );
      })}
    </div>
  );
};

export default DisplayPlans;
