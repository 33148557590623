import { toast } from 'react-hot-toast';
import axios from 'axios';
const fetchData = async (url, updates, type, auth) => {
  console.log(`https://api.doggel.co.uk/api/v1/${url}`, updates, type);
  if (type === 'get') {
    return await axios
      .get(`https://api.doggel.co.uk/api/v1/${url}`, {
        headers: { authorization: auth },
      })
      .then((data) => {
        return data.data;
      })
      .catch((e) => {
        toast.error(e.response.data.error, { duration: 3000 });
        console.log(e);
      });
  } else if (type === 'post') {
    return await axios
      .post(`https://api.doggel.co.uk/api/v1/${url}`, updates, {
        headers: { authorization: auth },
      })
      .then((data) => {
        toast.success('Success');
        return data.data.result;
      })
      .catch((e) => {
        toast.error(e.response.data.error, { duration: 3000 });
        console.log(e);
      });
  }
};

const getSubscriber = async (auth) => {
  return await fetchData('user', null, 'get', auth);
};

const setUser = async (cookie, setCookie) => {
  if (cookie.authorization && !cookie.userDetails) {
    const data = await getSubscriber(cookie.authorization);
    if (data !== undefined)
      setCookie('userDetails', JSON.stringify(data.result), { path: '/' });
    window.location.reload();
  }
};

const getBase64 = (file) => {
  return new Promise((resolve) => {
    let baseURL = '';
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      baseURL = reader.result;
      resolve(baseURL);
    };
  });
};
export default getSubscriber;
export { setUser, fetchData, getBase64 };
