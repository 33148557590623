import { useState } from 'react';
import {
  OutlinedInput,
  MenuItem,
  Select,
  TextField,
  Button,
} from '@mui/material';
import { fetchData } from '../../utils/apis';
import { useCookies } from 'react-cookie';
const types = ['yearly', 'monthly'];
const names = ['Standard', 'Premium', 'Gold'];
const CreatePlan = () => {
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [stripeId, setStripeId] = useState('');
  const [description, setDescription] = useState('');
  const [amount, setAmount] = useState(0.0);
  const [savings, setSavings] = useState(0.0);
  const [cookie] = useCookies();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const options = { id: stripeId, name, description, amount, savings, type };
    await fetchData('plan/create', options, 'post', cookie.authorization);
    window.location.reload();
  };

  const handleSelectChange = (event, setState) => {
    setState(event.target.value);
  };

  return (
    <div className='w-1/2 justify-center flex '>
      <form className={'w-3/4 flex  flex-col gap-1'} onSubmit={handleSubmit}>
        <TextField
          variant='outlined'
          margin='normal'
          required
          fullWidth
          value={stripeId}
          id='stripe id'
          label='stripe id'
          name='stripe id'
          autoComplete='stripe id'
          autoFocus
          onChange={(e) => {
            setStripeId(e.target.value);
          }}
        />
        <TextField
          variant='outlined'
          margin='normal'
          required
          fullWidth
          value={amount}
          name='amount'
          label='amount'
          type='float'
          id='amount'
          autoComplete='current-amount'
          onChange={(e) => {
            setAmount(e.target.value);
          }}
        />
        <TextField
          variant='outlined'
          margin='normal'
          required
          fullWidth
          value={savings}
          name='savings'
          label='savings'
          type='float'
          id='savings'
          autoComplete='current-savings'
          onChange={(e) => {
            setSavings(e.target.value);
          }}
        />
        <TextField
          variant='outlined'
          margin='normal'
          required
          fullWidth
          value={description}
          name='description'
          label='description'
          type='text'
          id='description'
          autoComplete='current-description'
          onChange={(e) => {
            setDescription(e.target.value);
          }}
        />
        <Select
          displayEmpty
          required
          value={name}
          onChange={(e) => handleSelectChange(e, setName)}
          input={<OutlinedInput />}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem disabled value=''>
            <em>Name</em>
          </MenuItem>
          {names.map((name) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
        </Select>
        <Select
          displayEmpty
          required
          value={type}
          onChange={(e) => handleSelectChange(e, setType)}
          input={<OutlinedInput />}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem disabled value=''>
            <em>Type</em>
          </MenuItem>
          {types.map((name) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
        </Select>
        <Button
          type='submit'
          fullWidth
          variant='contained'
          color='primary'
          className={'relative top-5'}
        >
          Add Plan
        </Button>
      </form>
    </div>
  );
};

export default CreatePlan;
