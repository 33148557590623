import React, { useState } from 'react';
import {
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  Divider,
  Checkbox,
} from '@mui/material';
import { useCookies } from 'react-cookie';
import { fetchData } from '../utils/apis';
const FAQ = () => {
  const [answer, setAnswer] = useState('');
  const [question, setQuestion] = useState('');
  const [cookie] = useCookies();
  const [selectedValue, setSelectedValue] = useState({});
  const [faqs, setFaqs] = useState([]);
  const handleGetFaq = async (e) => {
    e.preventDefault();
    const url = 'faqs';
    const { result } = await fetchData(url, null, 'get', cookie.authorization);
    setFaqs(result);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    const url = `faq/${selectedValue._id}/update`;
    const update = {
      question: selectedValue.question,
      answer: selectedValue.answer,
    };
    await fetchData(url, update, 'post', cookie.authorization);
    setSelectedValue({});
    handleGetFaq(e);
  };

  const handleDelete = async (e) => {
    e.preventDefault();

    const url = `faq/${selectedValue._id}/delete`;
    await fetchData(url, null, 'get', cookie.authorization);
    setSelectedValue({});
    handleGetFaq(e);
  };

  const handleFAQ = async (e) => {
    e.preventDefault();
    const url = `faq/create`;
    const update = { question: question, answer: answer };
    await fetchData(url, update, 'post', cookie.authorization);
  };

  return (
    <div className='w-full flex space-y-10 flex-col justify-center items-center'>
      <form className={'w-1/2 gap-1'} onSubmit={handleFAQ}>
        <TextField
          variant='outlined'
          margin='normal'
          required
          fullWidth
          id='question'
          label='Question'
          name='question'
          autoComplete='question'
          autoFocus
          onChange={(e) => {
            setQuestion(e.target.value);
          }}
        />
        <TextField
          variant='outlined'
          margin='normal'
          required
          fullWidth
          name='Answer'
          label='Answer'
          type='Answer'
          id='Answer'
          autoComplete='current-Answer'
          onChange={(e) => {
            setAnswer(e.target.value);
          }}
        />

        <Button
          type='submit'
          fullWidth
          variant='contained'
          color='primary'
          className={'space-x-3 space-y-2'}
        >
          Add FAQ
        </Button>
      </form>
      <form className='w-1/2 space-y-5' onSubmit={handleGetFaq}>
        <Button
          type='submit'
          fullWidth
          variant='contained'
          color='primary'
          className={'space-x-3 space-y-2'}
        >
          Get All FAQ
        </Button>
        <List
          component='nav'
          aria-label='mailbox folders'
          className='w-full max-h-[50v] overflow-y-auto'
        >
          {faqs.map((item) => {
            return (
              <div key={item._id}>
                <ListItem style={{ display: 'flex', flexDirection: 'column' }}>
                  <ListItemText> Question: {item.question} </ListItemText>
                  <ListItemText> Answer: {item.answer} </ListItemText>
                </ListItem>
                <Checkbox
                  onChange={(e) =>
                    e.target.checked
                      ? setSelectedValue(item)
                      : setSelectedValue({})
                  }
                />
                <Divider />
              </div>
            );
          })}
        </List>
      </form>
      {selectedValue._id && (
        <div className={' w-1/2 gap-1'}>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            value={selectedValue.question}
            id='question'
            label='Question'
            name='question'
            autoComplete='question'
            autoFocus
            onChange={(e) => {
              setSelectedValue({ ...selectedValue, question: e.target.value });
            }}
          />
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            value={selectedValue.answer}
            name='Answer'
            label='Answer'
            type='Answer'
            id='Answer'
            autoComplete='current-Answer'
            onChange={(e) => {
              setSelectedValue({ ...selectedValue, answer: e.target.value });
            }}
          />
          <div className='flex space-x-2'>
            <form onSubmit={handleUpdate}>
              <Button
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
                className={'space-x-3 space-y-2'}
              >
                Update
              </Button>
            </form>
            <form onSubmit={handleDelete}>
              <Button
                type='submit'
                fullWidth
                variant='contained'
                color='error'
                className={'space-x-3 space-y-2'}
              >
                Delete
              </Button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default FAQ;
