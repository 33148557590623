import React, { useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import { fetchData } from '../utils/apis';
import { useCookies } from 'react-cookie';
const Admin = () => {
  const [email, setEmail] = useState();
  const [cookie] = useCookies();
  const handleSubmit = async (e, value) => {
    e.preventDefault();
    const url = 'user/set-admin';
    const options = { email: email, isAdmin: value };
    await fetchData(url, options, 'post', cookie.authorization);
  };

  return (
    <div
      style={{ backgroundColor: '#ffffff', marginBottom: '8%' }}
      className='flex items-center h-[80vh]'
    >
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <div className={'flex flex-col space-y-5 items-center'}>
          <div className={'w-full gap-1'}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='email'
              label='Email Address'
              name='email'
              autoComplete='email'
              autoFocus
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <div className='flex space-x-2'>
              <form onSubmit={(e) => handleSubmit(e, true)}>
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  color='primary'
                  className={'space-x-3 space-y-2'}
                >
                  Set To Admin
                </Button>
              </form>
              <form onSubmit={(e) => handleSubmit(e, false)}>
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  color='error'
                  className={'space-x-3 space-y-2'}
                >
                  Remove Admin
                </Button>
              </form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Admin;
